<template>
  <v-container>
    <v-row class="text-center" justify="center">
      <v-col class="col-10 welcome">
        <h1 class="not-print">
          Welcome to personal development "Skills radar" tool
        </h1>

        <p class="subheading font-weight-regular">
          Here you can self evaluate your current level using <a
            href="http://www.engineeringladders.com"
            target="_blank"
          >Engineering Ladders framework</a>,
          and share result with your manager or colleagues or friends.
        </p>
      </v-col>
    </v-row>

    <v-row
      justify="center"
    >
      <v-container id="radar">
        <radar-chart :chart-data="datacollection" :options="options"></radar-chart>
        <div class="point-label" id="tech-label">Technology</div>
        <div class="point-label" id="sys-label">System</div>
        <div class="point-label" id="ppl-label">People</div>
        <div class="point-label" id="proc-label">Process</div>
        <div class="point-label" id="infl-label">Influence</div>
      </v-container>
      <div id="legend" @click="onChangeLegend"></div>
    </v-row>
    <v-row
      justify="center"
    >
      <v-radio-group row v-model="ladder" id="ladders">
        <v-radio
          :label="'Developer'"
          :value="1"
          v-on:change="onChangeLadder(1)"
        ></v-radio>
        <v-radio
          :label="'Tech Lead'"
          :value="2"
          v-on:change="onChangeLadder(2)"
        ></v-radio>
        <v-radio
          :label="'Technical Program Manager'"
          :value="3"
          @change="onChangeLadder(3)"
        ></v-radio>
        <v-radio
          :label="'Engineering Manager'"
          :value="4"
          @change="onChangeLadder(4)"
        ></v-radio>
      </v-radio-group>
    </v-row>

    <v-row
      justify="center"
      id="descs"
    >
      <v-col
        cols="10"
        class="desc"
      >
        <span class="font-weight-bold">
          Technology
        </span>
        <span> —
          {{ tech_desc(tech_level) }}
        </span>
      </v-col>

      <v-col
        cols="10"
        class="desc"
      >
        <span class="font-weight-bold">
          System
        </span>
        <span> —
          {{ sys_desc(sys_level) }}
        </span>
      </v-col>

      <v-col
        cols="10"
        class="desc"
      >
        <span class="font-weight-bold">
          People
        </span>
        <span> —
          {{ ppl_desc(ppl_level) }}
        </span>
      </v-col>
      <v-col
        cols="10"
        class="desc"
      >
        <span class="font-weight-bold">
          Process
        </span>
        <span> —
          {{ proc_desc(proc_level) }}
        </span>
      </v-col>

      <v-col
        cols="10"
        class="desc"
      >
        <span class="font-weight-bold">
          Influence
        </span>
        <span> —
          {{ infl_desc(infl_level) }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import RadarChart from './RadarChart.js'

  export default {
    name: 'Assessment',
    components: {
      RadarChart
    },

    data: () => ({
      datacollection: {},
      currentData: [1,1,1,1,1],
      nextData: [1,1,1,1,1],
      options: {
        legend: {
          display: false
        },
        scale: {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 6,
            display: false
          },
          pointLabels: {
            fontColor: 'rgba(0, 0, 0, 1)',
            fontSize: 20,
            display: false
          },
          angleLines: {
            lineWidth: 4,
            color: 'rgba(0, 0, 0, 1)',
            display: false
          },
          gridLines: {
            lineWidth: 1,
            color: 'rgba(0, 0, 0, 1)',
            borderDash: [5,5],
            display: false
          }
        },
        tooltips: {
          enabled: false,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            title: function(tooltipItem, data) {
              let i = tooltipItem[0].index;
              return data.labels[i].charAt(0) + data.datasets[0].data[i] + ': ' + data.datasets[0].levels[i];
            },
            label: (tooltipItem, data) => data.datasets[0].desrcs[tooltipItem.index]
          },
          custom: function(tooltip) {
            let tooltipEl = document.getElementById('chartjs-tooltip');

            if(!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<table></table>';
              this._chart.canvas.parentNode.appendChild(tooltipEl);
            }

            if(tooltip.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if(tooltip.yAlign) {
              tooltipEl.classList.add(tooltip.yAlign);
            }
            else {
              tooltipEl.classList.add('no-transform');
            }
            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            if(tooltip.body) {
              let innerHtml = '<thead>';

              innerHtml += '<tr><th>' + tooltip.title + '</th></tr>';
              innerHtml += '</thead><tbody>';
              innerHtml += '<tr><td>' + tooltip.body.map(getBody) + '</td></tr>';
              innerHtml += '</tbody>';
              const tableRoot = tooltipEl.querySelector('table');

              tableRoot.innerHTML = innerHtml;
            }
            const positionY = this._chart.canvas.offsetTop;
            const positionX = this._chart.canvas.offsetLeft;

            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = positionX + tooltip.caretX + 'px';
            tooltipEl.style.top = positionY + tooltip.caretY + 'px';
            tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
            tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
            tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
          },
        }
      },
      tech_level: 1,
      sys_level: 1,
      ppl_level: 1,
      proc_level: 1,
      infl_level: 1,
      tech_levels: [
        'Adopts',
        'Specializes',
        'Evangelizes',
        'Masters',
        'Creates',
      ],
      tech_desrcs: [
        'actively learns and adopts the technology and tools defined by the team',
        'is the go-to person for one or more technologies and takes initiative to learn new ones',
        'researches, creates proofs of concept and introduces new technologies to the team',
        'has very deep knowledge about the whole technology stack of the system',
        'designs and creates new technologies that are widely used either by internal or external teams'
      ],
      sys_levels: [
        'Enhances',
        'Designs',
        'Owns',
        'Evolves',
        'Leads',
      ],
      sys_desrcs: [
        'successfully pushes new features and bug fixes to improve and extend the system',
        'designs and implements medium to large size features while reducing the system’s tech debt',
        'owns the production operation and monitoring of the system and is aware of its SLAs',
        'evolves the architecture to support future requirements and defines its SLAs',
        'leads the technical excellence of the system and creates plans to mitigate outages'
      ],
      ppl_levels: [
        'Learns',
        'Supports',
        'Mentors',
        'Coordinates',
        'Manages',
      ],
      ppl_desrcs: [
        'quickly learns from others and consistently steps up when it is required',
        'proactively supports other team members and helps them to be successful',
        'mentors others to accelerate their career-growth and encourages them to participate',
        'coordinates team members providing effective feedback and moderating discussions',
        'manages the team members’ career, expectations, performance and level of happiness'
      ],
      proc_levels: [
        'Follows',
        'Enforces',
        'Challenges',
        'Adjusts',
        'Defines',
      ],
      proc_desrcs: [
        'follows the team processes, delivering a consistent flow of features to production',
        'enforces the team processes, making sure everybody understands the benefits and tradeoffs',
        'challenges the team processes, looking for ways to improve them',
        'adjusts the team processes, listening to feedback and guiding the team through the changes',
        'defines the right processes for the team’s maturity level, balancing agility and discipline'
      ],
      infl_levels: [
        'Subsystem',
        'Team',
        'Multiple Teams',
        'Company',
        'Community',
      ],
      infl_desrcs: [
        'makes an impact on one or more subsystems',
        'makes an impact on the whole team, not just on specific parts of it',
        'makes an impact not only his/her team but also on other teams',
        'makes an impact on the whole tech organization',
        'makes an impact on the tech community'
      ],
      d_levels: [
        [1,1,1,1,1],
        [1,2,2,2,1],
        [2,2,2,3,2],
        [3,3,3,3,2],
        [4,4,3,4,3],
        [5,5,3,4,4],
        [5,5,3,4,5]
      ],
      tl_levels: [
        [1,1,1,1,1],
        [2,3,4,4,1],
        [3,4,4,5,2],
        [4,5,4,5,3],
        [4,5,4,5,4]
      ],
      tpm_levels: [
        [1,1,1,1,1],
        [2,2,4,4,3],
        [2,2,4,5,3],
        [2,3,5,5,4],
        [2,4,5,5,5]
      ],
      em_levels: [
        [1,1,1,1,1],
        [3,3,5,4,2],
        [3,4,5,5,2],
        [3,4,5,5,3]
      ],
      d_levels_title: [
        "Developer 1",
        "Developer 2",
        "Developer 3",
        "Developer 4",
        "Developer 5",
        "Developer 6",
        "Developer 7"
      ],
      tl_levels_title: [
        "none",
        "Tech Lead 4",
        "Tech Lead 5",
        "Tech Lead 6",
        "Tech Lead 7"
      ],
      tpm_levels_title: [
        "none",
        "Technical Program Manager 4",
        "Technical Program Manager 5",
        "Technical Program Manager 6",
        "Technical Program Manager 7"
      ],
      em_levels_title: [
        "none",
        "Engineering Manager 5",
        "Engineering Manager 6",
        "Engineering Manager 7"
      ],
      hidden_legend_current: true,
      hidden_legend_next: true,
      ladder: 1,
      d_level_last: 1
    }),
    mounted () {
      if (localStorage.hidden_legend_current) {
        this.hidden_legend_current = (localStorage.hidden_legend_current == 'true');
      }
      if (localStorage.hidden_legend_next) {
        this.hidden_legend_next = (localStorage.hidden_legend_next == 'true');
      }
      if (localStorage.ladder) {
        this.ladder = parseInt(localStorage.ladder);
      }
      this.fillData();

      this.renderLegend();
      this.isChangeLevel();
      if (document.location.pathname.substr(0, 3) != '/u/' && document.location.pathname.substr(0, 2) == '/u')
        this.getAssessment();
    },
    methods: {
      tech_desc (val) {
        return this.tech_desrcs[val]
      },
      sys_desc (val) {
        return this.sys_desrcs[val]
      },
      ppl_desc (val) {
        return this.ppl_desrcs[val]
      },
      proc_desc (val) {
        return this.proc_desrcs[val]
      },
      infl_desc (val) {
        return this.infl_desrcs[val]
      },
      fillData () {
        this.datacollection = {
          labels: ['Technology', 'System', 'People', 'Process', 'Influence'],
          datasets:
          [
            {
              label: "Level",
              backgroundColor: "rgba(0,0,200,0.2)",
              borderColor: "rgba(0,0,200,1)",
              data: [
                this.tech_level+1,
                this.sys_level+1,
                this.ppl_level+1,
                this.proc_level+1,
                this.infl_level+1],
              levels: [
                this.tech_levels[this.tech_level],
                this.sys_levels[this.sys_level],
                this.ppl_levels[this.ppl_level],
                this.proc_levels[this.proc_level],
                this.infl_levels[this.infl_level]
              ],
              desrcs: [
                this.tech_desrcs[this.tech_level],
                this.sys_desrcs[this.sys_level],
                this.ppl_desrcs[this.ppl_level],
                this.proc_desrcs[this.proc_level],
                this.infl_desrcs[this.infl_level]
              ]
            },
            {
              label: "Current level",
              backgroundColor: "rgba(255,20,147,0.2)",
              borderColor: "rgba(255,20,147,1)",
              data: this.currentData,
              hidden: this.hidden_legend_current
            },
            {
              label: "Next level",
              backgroundColor: "rgba(255,140,0,0.2)",
              borderColor: "rgba(255,140,0,1)",
              data: this.nextData,
              hidden: this.hidden_legend_next
            }
          ]
        }
      },
      isChangeLevel () {
        let currLevels = this.getLevels();
        let currLevelsTitle = this.getLevelsTitle();
        let minLevel = this.findCurrLevel(currLevels);

        if (this.ladder > 1 && minLevel == 0){
          let minDevelopLevel = this.findCurrLevel(this.d_levels);
          this.datacollection.datasets[1].data = this.currentData = this.d_levels[minDevelopLevel];
          document.querySelector("#legend ul li:nth-child(2) .label").innerHTML =
            'Current level - <strong class="primary--text">'+this.d_levels_title[minDevelopLevel]+'</strong>';
        } else {
          this.datacollection.datasets[1].data = this.currentData = currLevels[minLevel];
          document.querySelector("#legend ul li:nth-child(2) .label").innerHTML =
            'Current level - <strong class="primary--text">'+currLevelsTitle[minLevel]+'</strong>';
          this.d_level_last = minLevel;
        }

        if (minLevel+1 < currLevels.length) {
          this.datacollection.datasets[2].data = this.nextData = currLevels[minLevel+1];
          document.querySelector("#legend ul li:nth-child(3) .label").innerHTML =
            'Next level - <strong class="primary--text">'+currLevelsTitle[minLevel+1]+'</strong>';
        } else {
          this.datacollection.datasets[2].data = this.nextData = [];
          document.querySelector("#legend ul li:nth-child(3) .label").innerHTML =
            'Next level - <strong class="primary--text">God</strong>';
        }
      },
      findCurrLevel (levels){
        let dataLength = this.datacollection.datasets[0].data.length;
        let diffs = [];
        for (let i=0; i < dataLength; i++) {
          diffs.push(this.comparisonLevels(i, levels));
        }
        return this.getMinLevel(levels, dataLength, diffs);
      },
      comparisonLevels (el, levels) {
        let diff = [];
        for (let i=0; i < levels.length; i++) {
          diff[i] = levels[i][el] - this.datacollection.datasets[0].data[el];
        }
        return diff;
      },
      getMinLevel (levels, dataLength, diffs){
        let min = levels.length;
        let tmp = 0;
        for (let i=0; i < dataLength; i++) {
          for (let j=levels.length-1; j >= 0; j--) {
            if (diffs[i][j] == 0 || diffs[i][j] < 0) {
              tmp = j;
              if ( min > tmp ) min = tmp;
              break;
            }
          }
        }
        return min;
      },
      getLevels () {
        switch (this.ladder) {
          case 1: return this.d_levels;
          case 2: return this.tl_levels;
          case 3: return this.tpm_levels;
          case 4: return this.em_levels;
          default: return this.d_levels;
        }
      },
      getLevelsTitle () {
        switch (this.ladder) {
          case 1: return this.d_levels_title;
          case 2: return this.tl_levels_title;
          case 3: return this.tpm_levels_title;
          case 4: return this.em_levels_title;
          default: return this.d_levels_title;
        }
      },
      renderLegend () {
        let html = [];
        html.push('<ul>');
        for (let i=0; i < this.datacollection.datasets.length; i++) {
          let style;
          if (i == 0 || (i == 1 && this.hidden_legend_current == false) || (i == 2 && this.hidden_legend_next == false)) style = '';
          else style = 'style="text-decoration:line-through"';
          let legend = this.datacollection.datasets[i];
          html.push('<li data-index-number='+i+' '+style+'><div class="color-box">');
          html.push('<span style="background-color:'+legend.backgroundColor+';border:3px solid '+legend.borderColor+'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>');
          html.push('<span class="label">'+legend.label+'</span>');
          html.push('</div></li>');
        }
        html.push('</ul>');
        document.getElementById('legend').innerHTML = html.join('');
      },
      onChangeLegend (el) {
        let legendEl = el.target.parentElement.parentElement;
        let legendElIndex = legendEl.dataset.indexNumber;
        if (legendElIndex == 0 || legendElIndex == undefined) return;
        if (legendEl.style.textDecoration == 'line-through') {
          legendEl.style.textDecoration = '';
          if (legendElIndex == 1) {
            localStorage.hidden_legend_current = this.hidden_legend_current = false;
          } else if (legendElIndex == 2)  {
            localStorage.hidden_legend_next = this.hidden_legend_next = false;
          }
        } else {
          legendEl.style.textDecoration = 'line-through';
          if (legendElIndex == 1) {
            localStorage.hidden_legend_current = this.hidden_legend_current = true;
          } else if (legendElIndex == 2) {
            localStorage.hidden_legend_next = this.hidden_legend_next = true;
          }
        }
        this.fillData();
      },
      onChangeLadder (el) {
        localStorage.ladder = this.ladder = el;
        this.isChangeLevel();
        this.fillData();
      },
      getAssessment () {
        this.axios
          .get(this.SERVER_URL+"/v1/assmnt/"+document.location.pathname.substr(2), { headers: { "Authorization": "Bearer " + localStorage.token } })
          .then((res) => {
            if (res.data.result == "error") {
              localStorage.removeItem('token');
            } else {
              let data = res.data.result.split('').map(i=>Number(i));
              this.tech_level = data[0] - 1;
              this.sys_level  = data[1] - 1;
              this.ppl_level  = data[2] - 1;
              this.proc_level = data[3] - 1;
              this.infl_level = data[4] - 1;
              this.fillData();
              this.isChangeLevel();
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }
</script>
<style>
  #radar {
    position: relative;
    max-width: 70vw;
    width: 700px;
    margin-top: 15px;
  }
  .point-label {
    position: absolute;
    font-size: 22px;
    color: black;
  }
  #tech-label {
    display: flex;
    justify-content: center;
    width: calc(100% - 24px);
    top: -24px;
  }
  #sys-label {
    top: 32%;
    left: 97%;
  }
  #ppl-label {
    top: 90%;
    left: 74%;
  }
  #proc-label {
    top: 90%;
    left: 15%;
  }
  #infl-label {
    top: 32%;
    left: -12%;
  }
  canvas {
    background-image: url("~@/assets/background.svg");
    background-size: 100% 100%;
  }
  .desc {
    margin-bottom: 40px;
  }
  #chartjs-tooltip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, .9);
    color: white;
    border-radius: 5px;
    pointer-events: none;
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
    max-width: 300px;
    min-width: 200px;
    z-index: 50;
    text-align: center;
  }
  #chartjs-tooltip th {
    font-size: 15px;
  }
  #legend {
    position: absolute;
    left: 68%;
    z-index: 1;
  }
  #legend li {
    list-style-type: none;
    cursor: pointer;
    padding-right: 20px;
  }
  #legend .color-box {
    user-select: none;
  }
  #legend .label {
    font-size: 17px;
    padding-left: 8px;
  }
  #legend .label strong {
    white-space: nowrap;
  }
  #ladders {
    margin-top: -25px;
    padding-bottom: 25px;
  }
  #descs {
    max-width: 1200px;
    margin: 0 auto;
  }
  @media (min-width: 320px) and (max-width: 667px) {
    .slider, .welcome {
      flex: 0 0 91.6666666667% !important;
      max-width: 91.6666666667% !important;
    }
    .v-input {
      margin-top: 10px;
    }
    .v-slider__ticks-container span:nth-of-type(even) .v-slider__tick-label {
      margin-top: -30px;
    }
    .v-slider__tick-label {
      white-space: pre-line !important;
    }
    .point-label {
      position: absolute;
      font-size: 19px;
      color: black;
    }
    #legend {
      position: relative;
      left: 0;
      padding-top: 15px;
    }
    #legend ul {
      display: flex;
    }
    #legend .label {
    }
    #legend strong {
      white-space: pre-line !important;
    }
    #tech-label {
      margin-top: 10px;
    }
    #sys-label {
      margin-left: -8px;
      margin-top: -25px;
    }
    #infl-label {
      margin-top: -25px;
    }
    #ladders {
      margin-top: 0px;
      display: flex;
      margin-left: 17%;
    }
    #ladders .v-radio {
      padding-top: 3px;
    }
  }
  @media (min-width: 320px) and (max-width: 568px) {
    .v-slider__tick-label {
      font-size: 14px;
    }
  }
</style>
