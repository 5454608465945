<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <h1>
          Welcome to personal engineering development tool
        </h1>

        <p class="subheading font-weight-regular">
          Here you can self evaluate your current level using Engineering Ladders framework,
          and share result with your direct manager or colleagues or friends.
          <br>
          <a
            href="http://www.engineeringladders.com"
            target="_blank"
          >A framework for Engineering Managers</a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'ForManagers',
  }
</script>
