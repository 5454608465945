import Vue from 'vue';
import VueRouter from 'vue-router'
import AboutUs from '../components/AboutUs.vue'
import ForManagers from '../components/ForManagers.vue'
import HelloWorld from '../components/HelloWorld.vue'
import Assessment from '../components/Assessment.vue'


Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: "/",
      component: HelloWorld
    },
    {
      path: "/u/:id",
      component: HelloWorld,
      name: "user"
    },
    {
      path: "/u*",
      component: Assessment,
      name: "link",
    },
    {
      path: "/about",
      component: AboutUs
    },
    {
      path: "/contact",
      component: AboutUs
    },
    {
      path: "/forManagers",
      component: ForManagers
    }
  ]
})
