import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './plugins/vue-router'
import axios from 'axios'
import VueHtml2Canvas from 'vue-html2canvas';
import $ from 'jquery';
import VueGtag from "vue-gtag";
import Meta from 'vue-meta';

Vue.use(Meta);
Vue.use(VueHtml2Canvas);

Vue.config.productionTip = false
Vue.prototype.axios = axios

if (process.env.NODE_ENV !== 'production') {
  Vue.prototype.SERVER_URL = "http://localhost:5000/api"
} else {
  Vue.prototype.SERVER_URL = "https://skills-radar.com/api"
}

Vue.use(VueGtag, {
  pageTrackerEnabled: process.env.NODE_ENV === 'production',
  config: { id: "G-2GCXQDCM92" }
}, router);

$('#auth2_script_id').remove();

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
